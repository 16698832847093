import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaLink } from "react-icons/fa";
import editor from "../../Assets/Projects/codeEditor.png";

function Projects() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My  <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"  data-aos="zoom-in-up" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://res.cloudinary.com/ddkdeeue3/image/upload/v1709379320/l3npjqvtmwmxnqif2qmh.gif"}
              isBlog={false}
              title="APPC"
              description="💻 Tech Talk:
              :
              Frontend: React (for a responsive UI)
              State Management: Redux Toolkit
              Backend: MERN Stack (MongoDB, Express.js, Node.js)
              Payment Gateway: Razor Pay
              Email Integration: Nodemailer
              
              💡 Key Features:
              Responsive design for a smooth user experience across devices.
              Redux Toolkit for efficient state management.
              MERN stack for robust backend operations. 
              Seamless payment processing with Razor Pay.
              Instant email notifications powered by Nodemailer.
              "
              ghLink="https://github.com/karthikch33/EcommerceFrontend"
              demoLink="https://appcq.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card" data-aos="zoom-in-up" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://res.cloudinary.com/ddkdeeue3/image/upload/v1709378464/slswmblndz9jsm1ufdkn.gif"}
              isBlog={false}
              title="Socc Student"
              description="
              💻 Tech Talk:
              
              Frontend Vibes: Crafted with React for a sleek user interface.
              Smooth Sailing: Redux Toolkit keeps things running like a charm.
              Backend Brilliance: MERN Stack (MongoDB, Express.js, Node.js) for a solid backend foundation.
              💡 Key Features:
              
              Slick Animations: Elevate experience with interactive visuals.
              Effortless State Handling: Thanks to Redux Toolkit, our data game is strong.
              Easy-Breezy Event Registration: Registering for events just got a whole lot smoother."
              ghLink="https://github.com/karthikch33/SoccFrontend"
              demoLink="https://soccportal.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card" data-aos="zoom-in-up" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://res.cloudinary.com/ddkdeeue3/image/upload/v1709378981/jtuso0jp1nfwsnwlhgtl.gif"}
              isBlog={false}
              title="Socc Admin"
              description=" 💻 Tech Talk:
              :
              Built using React for a sleek look and feel, Socc Club's frontend is backed by Redux Toolkit for smooth operations, and the MERN Stack (MongoDB, Express.js, Node.js) ensures a reliable backend.

              💡 𝗞𝗲𝘆 𝗙𝗲𝗮𝘁𝘂𝗿𝗲𝘀:
              Enjoy interactive visuals and tools designed for admins to effortlessly manage Socc Club events. The video takes you through the dynamic features that make the admin experience a breeze."
              ghLink="https://github.com/karthikch33/Socc-Frontend-Personal-"
              demoLink="https://soccadminportal.vercel.app"              
            />
          </Col>

         
          
          <Col md={4} className="project-card" data-aos="zoom-in-up" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://res.cloudinary.com/ddkdeeue3/image/upload/v1709533085/jjkajl87jg85jpnkk3hd.gif"}
              isBlog={false}
              title="Food Delivery System"
              description="           
              ​🇹​​🇪​​🇦​​🇲​ ​🇵​​🇷​​🇴​​🇯​​🇪​​🇨​​🇹​ ​🇨​​🇴​​🇱​​🇱​​🇴​​🇧​​🇷​​🇦​​🇹​​🇪​​🇩​ ​🇼​​🇮​​🇹​​🇭​ 3      ​🇲​​🇪​​🇲​​🇧​​🇪​​🇷​​🇸​                                                                                                                    
              Features:
               
              Developed a application based on Spring Boot to implement online food delivery system with the provision for the restaurants to update the Menus and also implemented micro services architecture using eureka "
              ghLink="https://github.com/harsha-pingali/Jfsd_sdp/tree/main/SDPWORKSPACE"
            
            />
          </Col>


          <Col md={4} className="project-card" data-aos="zoom-in-up" data-aos-duration="2950">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Space Management"
              description=" Features:

              Seamless integration with Stripe for secure transactions
              Efficient management with Strapi SAAS
              Enhanced user experience with Google authentication
              Human-friendly search functionality, because we're all about simplicity
              Check it out, and let me know what you think!"
              ghLink="https://github.com/karthikch33/SpaceManagment_Project"
            />
          </Col>

          <Col md={4} className="project-card" data-aos="zoom-in-up" data-aos-duration="2950">
            <ProjectCard
              imgPath={"https://res.cloudinary.com/ddkdeeue3/image/upload/v1709701168/efwodqec4qtbx8hkuqdw.gif"}
              isBlog={false}
              title="Java Script Mini Projects"
              description={<>
              <div style={{display:"flex", justifyContent:"space-between"}} >
                <label htmlFor="">Weather Application All Cities</label>
                <a href=" https://karthikch33.github.io/WeatherAppp/" style={{textDecoration:"None"}}> <FaLink /> Link <FaLink /></a>
              </div>

              <div style={{display:"flex", justifyContent:"space-between"}} className="mt-3">
                <label htmlFor="">Heart Symbol Trail Project</label>
                <a href=" https://karthikch33.github.io/Heart_Symbol_Trail_Project/" style={{textDecoration:"None"}}> <FaLink /> Link <FaLink /></a>
              </div>

              <div style={{display:"flex", justifyContent:"space-between"}} className="mt-3">
                <label htmlFor="">Simple Mini Calculator</label>
                <a href="  https://karthikch33.github.io/Calculator/" style={{textDecoration:"None"}}> <FaLink/> Link <FaLink /></a>
              </div>

              <div style={{display:"flex", justifyContent:"space-between"}} className="mt-3">
                <label htmlFor="">Quiz App</label>
                <a href="  https://karthikch33.github.io/Quiz_App" style={{textDecoration:"None"}}> <FaLink/> Link <FaLink /></a>
              </div>

              <div style={{display:"flex", justifyContent:"space-between"}} className="mt-3">
                <label htmlFor="">Analog Clock</label>
                <a href="  https://karthikch33.github.io/Analog-Clock/" style={{textDecoration:"None"}}> <FaLink/> Link <FaLink /></a>
              </div>
              </>}
              ghLink="https://github.com/karthikch33/JavaScript_Projects"
            />
          </Col>
        
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
